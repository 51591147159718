<template>
  <ul class="menu-nav">
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren(item.route),
        }"
        v-for="(item, index) in itemsMenu"
        :key="index"
    >
      <router-link :to="item.route" v-slot="{ href, navigate }" v-if="!item.subItems">
        <a :href="href" class="menu-link menu-toggle" @click="navigate">
          <i class="text-white menu-icon" :class="{[item.iconClass]: true}"></i>
          <span class="menu-text text-uppercase text-white">{{ item.title }}</span>
        </a>
      </router-link>

      <a href="#" class="menu-link menu-toggle" v-if="item.subItems">
        <i class="text-white menu-icon" :class="{[item.iconClass]: true}"></i>
        <span class="menu-text text-uppercase text-white"> {{ item.title }} </span>
        <i class="text-white menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
              :to="subItem.route"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-for="(subItem, index) in item.subItems" :key="index"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{ subItem.title }} </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li class="menu-section d-none">
      <h4 class="menu-text">Componentss</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
  </ul>
</template>

<script>
import sideMenu from "@/core/router/sideMenu";

export default {
  name: "KTMenu",

  data() {
    return {
      itemsMenu: sideMenu,
      sede_id: "0",
    };
  },
  created() {
   
  },
  watch: {
    sede_id: function () {
      this.$store.dispatch("vincularSede", this.sede_id);
    },
  },
  computed: {
   
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
