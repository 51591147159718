var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.itemsMenu),function(item,index){
var _obj;
return _c('li',{key:index,staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren(item.route),
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(!item.subItems)?_c('router-link',{attrs:{"to":item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var _obj;

      var href = ref.href;
      var navigate = ref.navigate;return [_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"text-white menu-icon",class:( _obj = {}, _obj[item.iconClass] = true, _obj )}),_c('span',{staticClass:"menu-text text-uppercase text-white"},[_vm._v(_vm._s(item.title))])])]}}],null,true)}):_vm._e(),(item.subItems)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"text-white menu-icon",class:( _obj = {}, _obj[item.iconClass] = true, _obj )}),_c('span',{staticClass:"menu-text text-uppercase text-white"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('i',{staticClass:"text-white menu-arrow"})]):_vm._e(),_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},_vm._l((item.subItems),function(subItem,index){return _c('router-link',{key:index,attrs:{"to":subItem.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(subItem.title)+" ")])])])]}}],null,true)})}),1)])],1)}),_vm._m(0)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section d-none"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Componentss")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])}]

export { render, staticRenderFns }