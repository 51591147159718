var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subheader py-2 py-lg-4",class:_vm.subheaderClasses,attrs:{"id":"kt_subheader"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap",class:{ 'container-fluid': _vm.widthFluid, container: !_vm.widthFluid }},[_c('div',{staticClass:"d-inline-flex align-items-center flex-wrap mr-1"},[_c('h5',{staticClass:"text-dark font-weight-bold my-2 mr-5"},[_vm._v(" "+_vm._s(_vm.breadcrumbs.length && _vm.breadcrumbs[0].title)+" ")]),(false)?_c('ul',{staticClass:"breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"},[_c('li',{staticClass:"breadcrumb-item d-inline-flex"},[_c('router-link',{staticClass:"subheader-breadcrumbs-home",attrs:{"to":'/'}},[_c('i',{staticClass:"flaticon2-shelter text-muted icon-1x"})])],1),_vm._l((_vm.breadcrumbs),function(breadcrumb,i){return [_c('li',{key:(i + "-" + (breadcrumb.id)),staticClass:"breadcrumb-item d-inline-flex"},[(breadcrumb.route)?_c('router-link',{key:i,staticClass:"text-muted",attrs:{"to":breadcrumb.route}},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e(),(!breadcrumb.route)?_c('span',{key:i,staticClass:"text-muted"},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e()],1)]})],2):_vm._e()]),(_vm.title == 'Empresas')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/empresas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createEmpresa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Novo ")])]}}],null,false,4127927891)})],1)],1):_vm._e(),(_vm.title == 'Departamentos')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/departamentos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createDepartamento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Novo ")])]}}],null,false,4127927891)})],1)],1):_vm._e(),(_vm.title == 'Usuarios')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/usuarios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createUsuario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Novo ")])]}}],null,false,4127927891)})],1)],1):_vm._e(),(_vm.title == 'Chatbots')?_c('div',[_c('b-button-group',[_c('router-link',{attrs:{"to":"/chatbots"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Listar ")])]}}],null,false,4165336199)}),_c('router-link',{attrs:{"to":"/createChatbots"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"btn btn-light font-weight-bold btn-sm ml-1",staticStyle:{"color":"black"},attrs:{"variant":"default"},on:{"click":navigate}},[_vm._v(" Novo ")])]}}],null,false,4127927891)})],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }