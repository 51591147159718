export default [
    {
        title: 'Página Inicial',
        route: '/dashboard',
        iconClass: 'fas fa-chalkboard-teacher',
    },
    {
        title: 'Empresas',
        route: '/empresas',
        iconClass: 'fas fa-address-card',
    },
    {
        title: 'Usuarios',
        route: '/usuarios',
        iconClass: 'fas fa-address-card',
    },
    {
        title: 'Atendentes',
        route: '/empresas',
        iconClass: 'fas fa-address-card',
    },
    {
        title: 'Chatbot',
        route: '/chatbots',
        iconClass: 'fas fa-address-card',
    },
    {
        title: 'Departamentos',
        route: '/departamentos',
        iconClass: 'fas fa-address-card',
    },
    {
        title: 'Campos Segmento',
        route: '/empresas',
        iconClass: 'fas fa-address-card',
    },
    {
        title: 'Relatórios',
        route: null,
        iconClass: 'fas fa-cogs',
        subItems: [
            {
                title: 'Funcionários',
                route: '/gerenFuncionarios',
            },
            {
                title: 'Permissões',
                route: '/permissoes',
            },
            {
                title: 'Disciplina',
                route: '/disciplina',
            },
            {
                title: 'Tipo de disciplica',
                route: '/tipoDisciplina',
            },
            {
                title: 'Empresa',
                route: '/empresa',
            },
            {
                title: 'Filial',
                route: '/filial',
            },
            {
                title: 'Convocatoria',
                route: '/convocatoria',
            },

            {
                title: 'Sala',
                route: '/sala',
            },
            {
                title: 'Local',
                route: '/local',
            },
            {
                title: 'Peril',
                route: '/perfil',
            },
            {
                title: 'Setor',
                route: '/setor',
            },
        ]
    }
]